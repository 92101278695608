import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const Narbenbehandlung = ({ data, location, ...props }) => {
    return (
        <Layout
            location={location}
            title="Narbenbehandlung"
            noSlider
            {...props}
        >
            <p>
                Narben entstehen durch eine Verletzung der Haut oder durch eine
                Hauterkrankung, zum Beispiel einer Akne. Die Form einer Narbe
                kann flach, tief, wulstig oder auch breit sein, rot oder
                blass-weiss. Und jede dieser Formen benötigt unterschiedliche
                Therapie Verfahren.
            </p>
            <p>
                Besonders Narben durch eine in der Pubertät abgelaufenen Akne
                Erkrankung bleiben zeitlebens bestehen.
            </p>
            <p>
                Eine Behandlung der Narben wird eher selten zu einem perfekten
                Ausgangszustand führen. Doch lässt sich in den meisten Fällen
                ein ästhetisch ausgezeichnetes, beeindruckendes Ergebnis im
                Vergleich zum Ausgangsbefund der Narbe erreichen.
            </p>
            <p>
                Folgende Behandlungsmöglichkeiten werden geboten:
                <ul>
                    <li>Lasertherapie</li>
                    <li>
                        Micro Needling, auch mit chem. Peeling und
                        Plasmatherapie kombiniert
                    </li>
                    <li>Kryotherapie</li>
                    <li>Injektionsverfahren mit Medikamenten</li>
                    <li>Silikonpflaster</li>
                    <li>Narbensalben</li>
                    <li>Radiofrequenzchirurgie</li>
                </ul>
            </p>

            <Questions title="Narbenbehandlung" />
        </Layout>
    );
};

export default Narbenbehandlung;
